.App {
  
  /* background-color: aliceblue; */
  flex: 1;
  height: 100vh;
}

.App-test {
  overflow: hidden;
  cursor: pointer;

}
.App-test:hover {
  overflow: auto;
}
.App-test::-webkit-scrollbar {
  width: 0.4em;
  background-color : rgba(66,66,66,0.4);
  border-radius: 5;
 
}
 
.App-test::-webkit-scrollbar-track {
  box-shadow : inset 0 0 6px rgba(0,0,0,0.2);
  border-radius: 10px;
}
 
.App-test::-webkit-scrollbar-thumb {
  background-color : rgba(0,0,0,0.2);
  outline : 1px solid slategrey;
  border-radius: 10px;
  
}

